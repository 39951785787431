import axios from "@/utils/axios";
import { LoginDataType, UploadFileType } from "app/types";

const API_BASE: string = process.env.GATSBY_API_BASE?.trimEnd();
const realm: string | undefined = process.env.GATSBY_API_AUTH_REALM;
console.log("realm", realm);
const clientSecret: string | undefined = process.env.GATSBY_API_CLIENT_SECRET;
const clientId: string = process.env.GATSBY_CLIENT_ID;
const grantType: string = "password";
const grantTypeRefresh: string = "refresh_token";
const scope: string = "openid";
const storage: Storage = typeof window !== "undefined" && window.localStorage;

const getToken = (): string | null => {
  return storage && storage.authtoken ? JSON.parse(storage.authtoken) : null;
};

const userName: string | null = typeof window !== "undefined"
  && window?.localStorage?.getItem("userName");

// Авторизация
export const login = async (username: string, password: string): Promise<any> => {
  const loginData: LoginDataType = {
    client_id: clientId,
    grant_type: grantType,
    client_secret: clientSecret,
    scope,
    username,
    password
  };

  return axios.POST_LOGIN(API_BASE, `/auth/realms/${realm}/protocol/openid-connect/token`, loginData);
};

// Регистрация
export const registration = async ({
  firstName,
  lastName,
  email,
  companyInn,
  password,
}): Promise<any> => {
  const registrationData = {
    firstName,
    lastName,
    email,
    companyInn,
    password,
  }

  return axios.POST(API_BASE, "/api/orders/v1/user/register", registrationData);
};

// Refresh
export const updateToken = async (refreshToken: string | null): Promise<any> => {
  const loginData: LoginDataType = {
    client_id: clientId,
    grant_type: grantTypeRefresh,
    client_secret: clientSecret,
    scope,
    refresh_token: refreshToken,
    username: userName
  };

  return axios.POST_LOGIN(API_BASE, `/auth/realms/${realm}/protocol/openid-connect/token`, loginData);
};

// Отзыв текущего токена
export const logout = async (): Promise<any> => {
  const logoutData = {
    token: getToken(),
    client_id: clientId,
    grant_type: grantType,
    client_secret: clientSecret,
    scope,
  };

  return axios.POST_LOGIN(API_BASE, `/auth/realms/${realm}/protocol/openid-connect/revoke`, logoutData);
};

// Получение данных об авторизованном пользователе
export const fetchUserInfo = async (): Promise<any> => {
  return axios.POST_LOGIN(API_BASE, `/auth/realms/${realm}/protocol/openid-connect/userinfo`);
};

export const resetPassword = async (email: string) => {
  return axios.POST(API_BASE, "auth/v1/reset-password", { "email": email });
};

export const confirmNewPasswordStatus = async (resetToken?: string) => {
  return axios.GET(API_BASE, `auth/v1/confirm-new-password${resetToken}`);
};

export const sendNewPassword = async (resetToken: string, newPassword: string) => {
  return axios.POST(API_BASE, `auth/v1/confirm-new-password${resetToken}`, { password: newPassword });
};

export const getUsersList = async (
  page: number,
  pageSize: number,
  search?: string,
  email?: string,
  roleId?: string,
  departmentId?: string,
) => {
  return axios.GET(API_BASE, "auth/v1/users",
    {
      page,
      pageSize,
      search,
      email,
      roleId,
      departmentId,
    },
  );
};

export const createUser = async (
  email: number,
  name: string,
  password: string,
  departmentId: string | number,
  roleId: string | number,
  isActive: boolean,
) => {
  return axios.POST(API_BASE, "auth/v1/users",
    {
      email,
      name,
      password,
      departmentId,
      roleId,
      isActive,
    }
  );
};

export const updateUser = async (
  id: number,
  name: string,
  email: string,
  password: string,
  departmentId: string | number,
  roleId: string | number,
  isActive: boolean,
  isSuperuser: boolean,
) => {
  return axios.PUT(API_BASE, `auth/v1/user/${id}`,
    {
      name,
      email,
      password,
      departmentId,
      roleId,
      isActive,
      isSuperuser,
    }
  );
};

export const deleteUser = async (id: number) => {
  return axios.DELETE(API_BASE, `auth/v1/user/${id}`);
};

export const getRolePerms = async () => {
  return axios.GET(API_BASE, "auth/v1/role/perms");
};

export const getRoles = async (name?: string) => {
  return axios.GET(API_BASE, "auth/v1/roles", { name });
};

export const createRole = async (name?: string, permissions?: string[]) => {
  return axios.POST(API_BASE, "auth/v1/roles", {
    name: name,
    permissions: permissions
  });
};

export const deleteRole = async (roleId: number) => {
  return axios.DELETE(API_BASE, `auth/v1/role/${roleId}`);
};

export const getRole = async (roleId: number) => {
  return axios.GET(API_BASE, `auth/v1/role/${roleId}`);
};

export const updateRole = async (
  roleId: number,
  name: string,
  permissions: string[]
) => {
  return axios.PUT(API_BASE, `auth/v1/role/${roleId}`, {
    name: name,
    permissions: permissions
  });
};

export const savePortalSettings = async (
  data: { portalName: string },
  logo: File | UploadFileType,
  favicon: File | UploadFileType,
) => {
  return axios.POST_FOR_AUTH(API_BASE, "auth/v1/settings", {
    data,
    logo: logo,
    favicon: favicon,
  });
};

export const getPortalSettings = async () => {
  return axios.GET(API_BASE, "auth/v1/settings");
};
